<template>
  <div id="cookies-policy">
    <div class="body-2 d-flex flex-column">
      <h2>{{ $t("cookies") }}</h2>
      <p class="legalTxt">
        {{ $t("cookies1") }}
      </p>
      <h3>{{ $t("cookieTypes") }}</h3>
      <h4>{{ $t("cookieType1") }}</h4>
      <p class="legalTxt">
        {{ $t("cookies2") }}
      </p>
      <v-simple-table class="legal">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-center">
                {{ $t("cookieName") }}
              </th>
              <th class="text-center">
                {{ $t("description") }}
              </th>
              <th class="text-center">
                {{ $t("duration") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ $t("cookieName1") }}</td>
              <td>{{ $t("desc1") }}</td>
              <td>{{ $t("duration1") }}</td>
            </tr>
            <tr>
              <td>{{ $t("cookieName2") }}</td>
              <td>{{ $t("desc2") }}</td>
              <td>{{ $t("duration2") }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <h4>{{ $t("cookieType2") }}</h4>
      <p class="legalTxt">
        {{ $t("cookies3") }}
      </p>
      <v-simple-table class="legal">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-center">
                {{ $t("cookieName") }}
              </th>
              <th class="text-center">
                {{ $t("description") }}
              </th>
              <th class="text-center">
                {{ $t("duration") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {{ $t("cookieName3") }} <br />
                {{ $t("cookieName3-2") }}
              </td>
              <td>{{ $t("desc3") }}</td>
              <td>
                {{ $t("duration3") }} <br />
                {{ $t("duration3") }}
              </td>
            </tr>
            <tr>
              <td>{{ $t("cookieName4") }}</td>
              <td>{{ $t("desc4") }}</td>
              <td>{{ $t("duration2") }}</td>
            </tr>
            <tr>
              <td>{{ $t("cookieName5") }}</td>
              <td>{{ $t("desc5") }}</td>
              <td>{{ $t("duration3") }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <h4>{{ $t("cookieType3") }}</h4>
      <p class="legalTxt">
        {{ $t("cookies4") }}
      </p>
      <v-simple-table class="legal">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-center">
                {{ $t("cookieName") }}
              </th>
              <th class="text-center">
                {{ $t("description") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {{ $t("cookieName4") }}
              </td>
              <td>{{ $t("desc6") }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <h4>{{ $t("cookieType4") }}</h4>
      <p class="legalTxt">
        {{ $t("cookies5") }}
      </p>
      <v-simple-table class="legal">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-center">
                {{ $t("cookieName") }}
              </th>
              <th class="text-center">
                {{ $t("description") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                SID <br />
                HSID <br />
                VISITOR_INFO1_LIVE <br />
                PREF <br />
                LOGIN_INFO <br />
                GPS <br />
                YSC
              </td>
              <td>{{ $t("desc7") }}</td>
            </tr>
            <tr>
              <td>
                APISID <br />
                SSID <br />
                SAPISID
              </td>
              <td>{{ $t("desc8") }}</td>
            </tr>
            <tr>
              <td>
                IDE <br />
                id <br />
              </td>
              <td>{{ $t("desc9") }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <h4>{{ $t("cookieManage") }}</h4>
      <p class="legalTxt">
        {{ $t("cookies6") }}
      </p>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'CookiesPolicy'
  };
</script>

<style lang="css" scoped>
  #cookies-policy {
    margin-top: -70px;
    padding: 20px;
  }

  .legalTxt {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.25px;
    color: #4E4B66;
  }

  .legalTxt,
  h2,
  h3,
  h4 {
    padding: 20px 8% 0px 8%;
  }

  .theme--light.v-data-table {
    background-color: transparent;
  }

  @media screen and (max-width: 600px) {
    #cookies-policy {
      margin-top: -128px;
    }

    .legalTxt,
    h2,
    h3 {
      padding: 10px 20px 0;
    }
  }
  </style>
